import React, { useEffect, useState, useRef } from "react";
//import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { accountService, alertService } from "../../_services";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import UploadService from "../../_services/FileUploadService";
import { useConfirm } from "material-ui-confirm";

function WriteUser() {
  const state = useLocation().state;
  const confirm = useConfirm();
  //console.log("ce vine");
  //console.log(state);
  const navigate = useNavigate();
  // const { id } = match.params;
  const [id, setId] = useState(state?.id || 0);
  const isAddMode = !id;
  const [username, setUsername] = useState(state?.username || "");
  const [firstName, setFirstName] = useState(state?.firstName || "");
  const [lastName, setLastName] = useState(state?.lastName || "");
  const [email, setEmail] = useState(state?.email || "");
  const [role, setRole] = useState(state?.role || "");
  const [phone, setPhone] = useState(state?.phone || "");
  const [active, setActive] = useState(state?.active || true);
  const [postal_code, setPostal_code] = useState(state?.postal_code || "");
  const [address, setAddress] = useState(state?.address || "");
  const [credits, setCredits] = useState(state?.credits || 0);
  //const [avatar, setAvatar] = useState(state?.avatar || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.avatar || "");

  const [imagePreviews, setImagePreviews] = useState([]);

  //console.log(isAddMode);
  const [status, setStatus] = useState(state?.active || 0);
  const initialValues = {
    username: username,
    firstName: firstName,
    lastName: lastName,
    email: email,
    role: role,
    password: "",
    phone: phone,
    active: active,
    address: address,
    postal_code: postal_code,
    birthDate: null,
    gender: null,
    avatar: pimg,
    confirmed: false,
    confirmPassword: "",
    myFile: "",
    credits:credits,
  };
  /*   const initialValues = {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
    };*/
  const filesharhe_ref = useRef();
  console.log("pimg 1 : ", pimg);
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.uploadavatar(file);
      console.log("res :", res.data);
      setPimg(res.data);
      console.log("res :", res.data, pimg);
      return res.data;
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      return false;
    }
  };

  async function loadimg() {
    try {
      if (file) {
        const imgUrl = await upload();
        //  fields["avatar"]=imgUrl;
        //  setPimg(imgUrl);

        console.log("imgUrl : ", imgUrl, pimg);
        // setPimg(imgUrl);
        // return true
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      // return false;
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    if (e.target.files[0].size > 2097152) {
      toast.error("Dimensiunea fișierului nu trebuie să depășească 2 MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setFile(e.target.files[0]);
      console.log(
        "fis : ",
        e.target.files[0].size,
        e.target.files[0],
        file,
        filesharhe_ref.current
      );
      //setFieldValue("myfile", e.currentTarget.files[0]);
      //fields["myfile"] = e.target.files[0];
      //setField("myfile", e.target.files[0]);
      setPimg(e.target.files[0].name);
      let images = [];

      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      setImagePreviews(images);
    }
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("NickName is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    role: Yup.string().required("Role is required"),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password) return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),
    myfile: Yup.mixed().test(
      "2097",
      "Fișierul încărcat este prea mare.",
      (value) => {
        return value && filesharhe_ref.current
          ? filesharhe_ref.current.files[0].size <= 2097152
            ? true
            : false
          : true;
      }
    ),
  });

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    try {
      //
      //const ok = await loadimg();
      // fields["active"] = status;
      if (file) {
        const imgUrl = await upload();
        //  fields["avatar"]=imgUrl;
        //  setPimg(imgUrl);

        if (imgUrl) {
          fields["avatar"] = imgUrl;
          setPimg(imgUrl);
          console.log("imgUrl : ", imgUrl, fields["avatar"]);
        }
        // setPimg(imgUrl);
        // return true
      }

      setStatus();
      //fields["avatar"] = pimg;
      if (pimg === "") {
        fields["avatar"] = pimg;
      }
      console.log("myfile : ", fields["avatar"], pimg);
      // fields["myfile"] = file;
      /* if(file) {
      fields["avatar"] = [...file];
    }*/
      //  console.log("myfile", fields["myfile"], file);
      if (isAddMode) {
        createUser(fields, setSubmitting);
      } else {
        updateUser(id, fields, setSubmitting);
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      // return false;
    }
  }

  function createUser(fields, setSubmitting) {
    accountService
      .create(fields)
      .then(() => {
        alertService.success("Utilizatorul adăugat cu succes", {
          keepAfterRouteChange: true,
        });
        toast.success("Utilizatorul adăugat cu succes");
        navigate("admin/users");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
        toast.error(error);
      });
  }

  function updateUser(id, fields, setSubmitting) {
    fields["active"] = status;
    //  fields["avatar"] = pimg;
    /*if (imagePreviews) {
      fields["avatar"] = imagePreviews;
    }*/
    //console.log("myfile 2 : ", fields["myfile"]); , file, imagePreviews
    console.log(JSON.stringify({ fields }));
    console.log("pimg updateUser : ", pimg);
    // //window.alert(JSON.stringify({ fields }));
    const formData = new FormData();
    for (let value in fields) {
      formData.append(value, fields[value]);
    }
    //formData.append("file", file);
    console.log("formData", formData);
    accountService
      .update(id, formData)
      .then(() => {
        // alertService.success("Actualizare cu succes", {
        //   keepAfterRouteChange: true,
        // });
        toast.success("Actualizare cu succes");
        navigate("/admin/users");
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
        // alertService.error(error);
      });
  }
  async function deleteAvatar(e, name) {
    e.preventDefault();
    //if (window.confirm("Stergi imaginea? "+name))
    //{
    console.log("sterge:", name, name.split("_")[0]);
    const poza = name.split("_")[0];
    ////window.alert(name);
    //console.log("sterge "+name);
    // history("/");
    //let _progressInfos = [...progressInfosRef.current.val];
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv imaginea ${poza}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        UploadService.deleteimgav(name, (event) => {})
          .then(() => {
            toast.success(
              "Imaginea a fost stearsa cu succes: " + name.split("_")[0]
            );
            setPimg("");
            //            fields["avatar"] = "";

            // return;
          })
          .catch(() => {
            //  _progressInfos[10].percentage = 0;
            //  setProgressInfos({ val: _progressInfos });
            //toast.error("Nu s-a putut sterge imaginea: " + name);
          });
        //}
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Box m="20px">
            <Form encType="multipart/form-data">
              <h1>
                {isAddMode ? "Adăugați utilizator" : "Editați utilizatorul"}
              </h1>
              <div className="form-row">
                <div className="form-group col">
                  <label>NickName</label>
                  <Field
                    name="username"
                    type="text"
                    className={
                      "form-control" +
                      (errors.username && touched.username ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-5">
                  <label>Nume</label>
                  <Field
                    name="firstName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.firstName && touched.firstName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-5">
                  <label>Prenume</label>
                  <Field
                    name="lastName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.lastName && touched.lastName ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-7">
                  <label>Email</label>
                  {!isAddMode ? (
                    <>
                      <Field
                        name="email"
                        type="text"
                        readOnly
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </>
                  )}
                </div>
                <div className="form-group col">
                  <label>Telefon</label>
                  <Field
                    name="phone"
                    type="text"
                    className={
                      "form-control" +
                      (errors.phone && touched.phone ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  <label>Adresa</label>
                  <Field
                    name="address"
                    type="text"
                    className={
                      "form-control" +
                      (errors.address && touched.address ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-2">
                  <label>Cod Postal</label>
                  <Field
                    name="postal_code"
                    type="text"
                    className={
                      "form-control" +
                      (errors.postal_code && touched.postal_code
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="postal_code"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-2">
                <label>Credite</label>
                <Field name="credits" type="number"  step="any" className={'form-control' + (errors.credits && touched.credits ? ' is-invalid' : '')} />
                <ErrorMessage name="credits" component="div" className="invalid-feedback" />
              </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Rol</label>
                  <Field
                    name="role"
                    as="select"
                    className={
                      "form-control" +
                      (errors.role && touched.role ? " is-invalid" : "")
                    }
                  >
                    <option value=""></option>
                    <option value="user">User</option>
                    <option value="bona">Worker</option>
                    <option value="manager">Manager</option>
                    <option value="admin">Admin</option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col">
                  <label>
                    Status{" "}
                    {status ? (
                      <div className="badge badge-primary">Activ</div>
                    ) : (
                      <div className="badge badge-danger">Inactiv</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="active"
                      onChange={(e) => setStatus(!status)}
                      className={
                        "form-control d-none" +
                        (errors.active && touched.active ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  {pimg && !file ? (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => deleteAvatar(e, pimg)}
                    >
                      <span>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                      Sterge Poza
                    </button>
                  ) : (
                    <>
                      <label
                        className="file btn btn-sm btn-info"
                        htmlFor="file"
                      >
                        Incarca avatar
                      </label>
                      {/* <input
                    type="file"
                    name="myfile"
                    accept="image/*"
                    onChange={(e) => {
                      setFieldValue("myfile", e.target.files[0]);
                      handleClick(e);
                    }}
                  />
                   <Field
                    innerRef={filesharhe_ref}
                    type="file"
                    id="myfile"  
                    name="myfile"          
                    onChange={(e) => {
                      setFieldValue("myfile", e.target.files[0]);
                      handleClick;
                    }}
                    accept=".jpg, .png, .gif, .svg, .webp"
                    /> 
                  <ErrorMessage
                    name="myfile"
                    component="div"
                    className="invalid-feedback"
                  />*/}
                      <Field
                        innerRef={filesharhe_ref}
                        type="file"
                        name="myfile"
                        accept=".jpg, .png, .gif, .svg, .webp"
                        onChange={handleClick}
                      />
                      <ErrorMessage
                        name="myfile"
                        component="div"
                        className="invalid-feedback"
                      />
                    </>
                  )}
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/users/avatar/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              {!isAddMode && (
                <div>
                  <h3 className="pt-3">Schimbaţi parola</h3>
                  <p>Lăsați necompletat pentru a păstra aceeași parolă</p>
                </div>
              )}

              <div className="form-row">
                <div className="form-group col">
                  <label>Parola</label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col">
                  <label>Confirmă parola</label>
                  <Field
                    name="confirmPassword"
                    type="password"
                    className={
                      "form-control" +
                      (errors.confirmPassword && touched.confirmPassword
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Salvează
                </button>
                <Link
                  to={isAddMode ? "/admin/users" : "/admin/users"}
                  className="btn btn-link"
                >
                  Abandon
                </Link>
              </div>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}

export { WriteUser };
